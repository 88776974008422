import React, { useEffect, useRef, useState } from "react";
import Utilities from "../../lib/Utilities";
import PaymentRequestRegisterTable from "./PaymentRequestRegisterTable";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import axios from "axios";
import CodeList from "../../lib/CodeList";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const TAX_TYPE = [
  { text: "과세", value: "A" },
  { text: "영세", value: "B" },
  { text: "면세", value: "C" },
];
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

const createOrderNumber = () => {
  let today = new Date();
  return `Danal_${util.todayDate("YYYYMMDD")}${
    today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()
  }${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()}${
    today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()
  }_main_${util.makeUUIDv4Short()}`;
};

const paymentInitData = {
  pmOderID: createOrderNumber(),
  pmProductName: "",
  pmCompanyName: "",
  pmCustomerName: "",
  pmCTel: "",
  pmCID: "",
  pmTotalCost: "",
  pmBaseCost: "",
  pmTax: "",
  pmMemo: "",
  pmMessage: "",
  pmFiles: "",

  subData: [
    {
      psOderID: createOrderNumber(),
      psOderIDSub: "",
      pmProductName: "",
      psTotalCost: "",
      psBaseCost: "",
      psTax: "",
      psTaxType: "",
      psPayStatus: "",
      psOrderURL: "",
      psCancelURL: "",
      psReceiptURL: "",
      psMemo: "",
    },
  ],
};
const paymentPreviewInitData = {
  psOderID: "",
  psOderIDSub: "",
  pmProductName: "",
  psTotalCost: "",
  psBaseCost: "",
  psTax: "",
  psTaxType: "",
  psPayStatus: "",
  psOrderURL: "",
  psCancelURL: "",
  psReceiptURL: "",
  psMemo: "",
};
const _mClassName = "PaymentRequestRegister";

function PaymentRequestRegister(props) {
  const { userInfo } = props;
  const [paymentCount, setPaymentCount] = useState(0);
  const [previewType, setPreviewType] = useState("A");
  const [loadingShortenLink, setLoadingShortenLink] = useState(false);
  const [loading, setLoading] = useState(true);

  const [previewCurrentData, setPreviewCurrentData] = useState({
    ...paymentPreviewInitData,
  });

  const [paymentData, setPaymentData] = useState({ ...paymentInitData });
  const $controller = {
    pmCompanyName: useRef(),
    pmMemo: useRef(),
    pmFiles: useRef(),
    pmCTel: useRef(),
  };

  const ShowPreViewParent = (data) => {
    setPaymentData({ ...paymentData, subData: [...data] });
  };

  const ShowPreView = () => {
    setPaymentData({
      ...paymentData,
      pmMemo: $controller.pmMemo.current.value,
      pmCompanyName: $controller.pmCompanyName.current.value,
      pmCTel: $controller.pmCTel.current.value,
    });
  };

  //결제 정보 변경이벤트
  let PaymentDataChange = (key, value) => {
    if (typeof key === "string") {
      setPaymentData({
        ...paymentData,
        [key]: value,
      });
    }
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_PAYMENT_REGISTER_TABLE_DATA_CHANGED,
      _mClassName,
      (b, n) => {
        setPaymentData({ ...paymentData, ...n });
        let data = null;
        for (let i = 0; i < n.subData.length; i++) {
          if (n.subData[i].psTaxType === previewType) data = n.subData[i];
        }
        if (data !== null) setPreviewCurrentData(data);
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_PAYMENT_REGISTER_TABLE_DATA_CHANGED,
        _mClassName
      );
    };
  }, []);

  //결제 항목 추가 삭제
  let PaymentDetailChange = (index, count, id) => {
    let dataList = [...paymentData.paymentDetail];
    if (count > paymentCount) {
      let uid = util.makeUUIDv4();
      dataList.push({
        id: uid,
        tax_type: "",
        service_name: "",
        base_cost: 0,
        tax: 0,
        service_memo: "",
      });
    } else {
      let del = dataList.findIndex((data) => {
        return data.id === id;
      });
      dataList.splice(del, 1);
    }
    setPaymentData({
      ...paymentData,
      paymentDetail: [...dataList],
    });
    setPaymentCount(count);
  };

  //결제항목 상세정보 변경
  let PaymentDataDetailChange = (e, key, uid) => {
    let dataList = [...paymentData.paymentDetail];
    let findIndex = dataList.findIndex((data) => {
      return data.id === uid;
    });
    let findItem = dataList.find((data) => {
      return data.id === uid;
    });
    let setItem = {
      ...findItem,
      [key]: e.target.value,
    };
    dataList.splice(findIndex, 1, setItem);
    setPaymentData({
      ...paymentData,
      paymentDetail: [...dataList],
    });
  };

  const logoChanged = (e) => {
    requestPhotoUpload(e.target.files);
  };

  function requestPhotoUpload(filedata, callback) {
    let formData = new FormData();
    formData.append("fileToUpload", filedata[0]);
    let realFileName = filedata[0]["name"];

    axios
      .post(
        "//cardlaw.co.kr/backside/payment/JS_payment_upload_file_save.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data["ret"] === true) {
          //setLogoUrl(response.data["file"]);
          let changedFileName = response.data["file"];
          let filename = paymentData.pmFiles;
          if (filename !== "") {
            filename += "|";
          }
          filename += `${realFileName}:${changedFileName}`;

          setPaymentData({ ...paymentData, pmFiles: filename });
        } else {
          codeList.Modal.current.alert(
            "업로드 중 문제가 발생하였습니다. 관리자에게 문의 바랍니다.",
            () => {}
          );
        }
      })
      .catch((response) => {
        console.log("Error!", response);
      });
  }

  //총액 합산
  let SetTotalPaymentData = () => {
    let total_base_cost = 0;
    let total_tax = 0;
    let total_cost = 0;
    let dataList = [...paymentData.paymentDetail];
    dataList.forEach((data) => {
      total_base_cost = total_base_cost + Number(data.base_cost);
      total_tax = total_tax + Number(data.tax);
    });
    total_cost = total_base_cost + total_tax;
    setPaymentData({
      ...paymentData,
      total_base_cost: total_base_cost,
      total_tax: total_tax,
      total_cost: total_cost,
    });
  };

  const downloadAs = (url, name) => {
    axios
      .post(
        "//cardlaw.co.kr/backside/payment/JS_payment_download_file_save.php",
        {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
          original: url,
          renamed: name,
        }
      )
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = name;
        a.click();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const deleteFile = (url, name) => {
    const data = { ...paymentData };
    let splitData = data.pmFiles.split("|");
    let index = splitData.findIndex((item) => {
      return item === `${name}:${url}`;
    });

    if (index > -1) {
      splitData.splice(index, 1);
      const pmFiles = splitData.join("|");
      setPaymentData({ ...paymentData, pmFiles: pmFiles });
    }
  };

  const createShortLinks = () => {
    if (userInfo.uAgreeYN === "N") {
      codeList.Modal.current.alert("가입승인이 필요한 서비스입니다.");
      return;
    }

    setLoadingShortenLink(true);
    paymentData.subData.map((data, index) => {
      setTimeout(() => {
        makeShortenLink(
          paymentData.pmOderID,
          paymentData.subData[index],
          index
        );
      }, index * 1000);
    });

    setTimeout(() => {
      setPaymentData({ ...paymentData });
      setLoadingShortenLink(false);
    }, paymentData.subData.length * 1000);
  };

  const makeShortenLink = (pmOderID, data, index) => {
    let msgID = util.makeUUIDv4();
    socket.getShortTag(
      {
        long_url: `https://cardlaw.co.kr/backside/danal_order_select.php?order_main=${pmOderID}&order_sub=${data.psOderIDSub}`,
      },
      msgID,
      (beforeData, newData) => {
        console.log(newData);
        /*for (let i = 0; i < paymentData.subData.length; i++) {
          setTimeout(() => {
            makeShortenLink(paymentData.subData[i], i);
          }, i * 500);
        }
        */
        data.psOrderURL = newData["link"];

        /*datanewData["link"];*/
      }
    );
  };

  const actionSave = (e) => {
    if (userInfo.uAgreeYN === "N") {
      codeList.Modal.current.alert("가입승인이 필요한 서비스입니다.");
      return;
    }

    setPaymentData({
      ...paymentData,
      pmMemo: $controller.pmMemo.current.value,
      pmCompanyName: $controller.pmCompanyName.current.value,
      pmCTel: $controller.pmCTel.current.value,
    });

    setLoading(true);
    if (checkValidation()) {
      let updateData = { ...paymentData };

      requestDataUpdate(updateData, EDIT_TYPE.INSERT);
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    if ($controller.pmCompanyName.current.value.trim() === "") {
      codeList.Modal.current.alert("거래처 상호명을 입력해 주세요.", () => {});
      return false;
    }
    if (paymentData.pmProductName.trim() === "") {
      codeList.Modal.current.alert(
        "상품 서비스명은 필수 입력사항 입니다.",
        () => {}
      );
      return false;
    }
    if (paymentData.subData[0].psBaseCost.trim() === "") {
      codeList.Modal.current.alert(
        "공급가액은 필수 입력사항 입니다.\n불필요한 항목은 삭제 후 시도해주세요.",
        () => {}
      );
      return false;
    }
    for (let i = 0; i < paymentData.subData.length; i++) {
      if (paymentData.subData[i].psOrderURL.trim() === "") {
        codeList.Modal.current.alert(
          "결제링크가 없는 항목이 있습니다.\n결제링크 생성 후 시도해주세요.",
          () => {}
        );
        return false;
      }
    }

    return true;
  };

  const requestDataUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/payment/JS_payment_request_insert.php",
      msgID: msgID,
      data: {},
      ...updateData,
      pmCreator: util.buddibleDecrypt(userInfo["uID"]),
    };

    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          setLoading(false);
          socket.sendLocalMessage(
            MsgIDList.EVENT_PAYMENT_REGISTER_DATA_CHANGED,
            _mClassName,
            newData,
            () => {}
          );
          codeList.Modal.current.alert("정보 저장이 완료되었습니다.", () => {});
          clearAllData();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            "저장 중 오류가 발생하였습니다.",
            () => {}
          );
        }
      } else {
        setLoading(false);
        codeList.Modal.current.alert(
          "저장 중 오류가 발생하였습니다.",
          () => {}
        );
      }
    });
  };

  const clearAllData = () => {
    for (let key in $controller) {
      if ($controller.hasOwnProperty(key)) {
        if ($controller[key].current) $controller[key].current.value = "";
      }
    }
    setPreviewCurrentData({
      ...paymentPreviewInitData,
    });
    setPaymentData({
      ...paymentInitData,
      pmOderID: createOrderNumber(),
    });
    socket.sendLocalMessage(
      MsgIDList.EVENT_PAYMENT_REGISTER_DATA_CLEAR_ALL,
      _mClassName,
      null,
      () => {}
    );
  };
  return (
    <>
      <div className="container-fluid">
        <div className="content">
          <div className="container-fluid p-0">
            <div className="d-sm-flex flex-row align-items-end justify-content-start mb-4">
              <h1 className="h3 mb-0 text-gray-800">결제 요청서 등록</h1>
              {userInfo.uAgreeYN === "N" && (
                <p className="font-weight-bold ml-3 mb-0">
                  <span className="text-danger">※ 결제 활성화 승인전</span> : 내
                  정보 관리에서 결제 활성화 신청 후 사용해주세요{" "}
                </p>
              )}
            </div>
            <div className="row">
              <div className="col-12 col-lg-7 ">
                <div className="card">
                  <div className="card-body">
                    <div className="form-group">
                      <label>1. 의뢰인(CLIENT)명</label>
                      <input
                        type="text"
                        className="form-control"
                        ref={$controller.pmCompanyName}
                      />
                    </div>
                    <div className="form-group">
                      <label>2. 의뢰인(CLIENT) SMS발송 연락처</label>
                      <input
                        type="tel"
                        className="form-control"
                        ref={$controller.pmCTel}
                      />
                    </div>

                    <div className="row mb-3">
                      <div className="col-12 mb-2">3. 결제요청 금액 입력</div>
                      <div className="col-12">
                        <PaymentRequestRegisterTable
                          ShowPreViewParent={ShowPreViewParent}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label">4. 파일첨부</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={logoChanged.bind(this)}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          파일첨부
                        </label>
                      </div>
                      {paymentData.pmFiles !== "" &&
                        paymentData.pmFiles.split("|").map((v, i) => {
                          let splitData = v.split(":");

                          if (splitData.length === 1) return null;
                          let fileName = splitData[0];
                          let fileLink = `//cardlaw.co.kr/uploadFiles/attach/${splitData[1]}`;
                          return (
                            <div className="my-2" key={i}>
                              {/* <button
                                onClick={() => {
                                  downloadAs(fileLink, fileName);
                                }}
                              >
                                {fileName}
                              </button>*/}
                              <button
                                className="btn btn-danger mr-2"
                                onClick={() => {
                                  deleteFile(splitData[1], fileName);
                                }}
                              >
                                삭제
                              </button>
                              {fileName.endsWith(".pdf") && (
                                <a
                                  className="font-weight-bold text-decoration-underline"
                                  href={fileLink}
                                  download={fileName}
                                  target="_blank"
                                >
                                  {fileName}
                                </a>
                              )}
                              {!fileName.endsWith(".pdf") && (
                                <a
                                  className="font-weight-bold text-decoration-underline"
                                  href={`//cardlaw.co.kr/backside/payment/JS_payment_download_file_save.php?original=${splitData[1]}&renamed=${fileName}`}
                                  target="_blank"
                                >
                                  {fileName}
                                </a>
                              )}
                            </div>
                          );
                        })}
                    </div>

                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">5. 전달 메세지</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="메세지를 입력해 주세요"
                        ref={$controller.pmMemo}
                      />
                    </div>

                    <div
                      className="btn-toolbar justify-content-between"
                      role="toolbar"
                      aria-label="Toolbar with button groups"
                    >
                      <div className="input-group">
                        <button className="btn btn-dark" onClick={ShowPreView}>
                          미리보기 적용
                        </button>

                        <button
                          className="btn btn-primary custom-border-primary custom-bg-primary ml-2"
                          onClick={createShortLinks}
                          disabled={loadingShortenLink}
                        >
                          결제링크 생성
                          {loadingShortenLink && (
                            <span
                              className="spinner-border spinner-border-sm ml-2"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </div>
                      <div className="input-group">
                        <button
                          className="btn btn-danger ml-2"
                          onClick={() => {
                            codeList.Modal.current.confirm(
                              "데이터를 초기화 하겠습니까?",
                              (ret) => {
                                if (ret) clearAllData();
                              }
                            );
                          }}
                        >
                          데이터 초기화
                        </button>
                        <button
                          className="btn btn-primary custom-border-primary custom-bg-primary ml-2"
                          onClick={() => {
                            codeList.Modal.current.confirm(
                              "결제요청서를 생성 하겠습니까?",
                              (ret) => {
                                if (ret) actionSave();
                              }
                            );
                          }}
                        >
                          결제요청서 생성
                        </button>
                      </div>
                    </div>

                    <div
                      className="container-fluid p-2 mt-2"
                      style={{ background: "#fafafa" }}
                    >
                      {paymentData.subData.length > 0 &&
                        paymentData.subData.map((v, i) => {
                          if (v.psOrderURL === "") {
                            return (
                              <div className="my-1" key={i}>
                                <span className="text-danger mt-2">
                                  결제링크가 없습니다. 결제링크 생성을
                                  눌러주세요.
                                </span>
                              </div>
                            );
                          } else {
                            return (
                              <div className="my-1" key={i}>
                                <span className="text-primary mt-2">
                                  결제링크가 생성되었습니다.
                                </span>
                                {/*<a
                                  className="ml-2 font-weight-bold text-decoration-underline"
                                  href={v.psOrderURL}
                                  target="_blank"
                                >
                                  미리보기
                                </a>*/}
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-lg-5" style={{ maxWidth: 800 }}>
                <div className="card">
                  <div className="card-body">
                    <div className="container">
                      <div className="row">
                        <label>※ 결제유저의 미리보기 화면입니다. ※</label>
                      </div>
                    </div>

                    <div className="container">
                      {/*결제 간략 정보*/}
                      <div className="row row-cols-2 py-3 my-3 custom-header-dark">
                        <div className="col mb-2">결제요청자</div>
                        <div className="col mb-2">
                          {userInfo && userInfo["uCoName"]}
                        </div>
                        <div className="col mb-2">사건명</div>
                        <div className="col mb-2">
                          {paymentData.pmProductName}
                        </div>
                        <div className="col">결제요청금액</div>
                        <div className="col">
                          {util.addCommas(
                            previewCurrentData.psTotalCost
                              .toString()
                              .replace(/[^0-9]/gi, "")
                          )}{" "}
                          원
                        </div>
                      </div>
                      {/*결제 정보 테이블*/}
                      <div className="row">
                        <div className="col p-0">
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                <th>사건명</th>
                                <th className="text-end">공급가액</th>
                                <th className="text-end">부가세</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{previewCurrentData.pmProductName}</td>
                                <td className="text-end">
                                  {util.addCommas(
                                    previewCurrentData.psBaseCost
                                      .toString()
                                      .replace(/[^0-9]/gi, "")
                                  )}
                                </td>
                                <td className="text-end">
                                  {util.addCommas(
                                    previewCurrentData.psTax
                                      .toString()
                                      .replace(/[^0-9]/gi, "")
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <th className="font-weight-bold">합계</th>
                                <th>&nbsp;</th>
                                <td className="font-weight-bold text-end">
                                  {previewCurrentData.psTotalCost}
                                </td>
                              </tr>
                              <tr>
                                <th colSpan={3}>{previewCurrentData.psMemo}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/*첨부파일*/}
                      <div className="row py-2">
                        {paymentData.pmFiles !== "" &&
                          paymentData.pmFiles.split("|").map((v, i) => {
                            let splitData = v.split(":");
                            if (splitData.length === 1) return null;
                            let fileName = splitData[0];
                            let fileLink = `//cardlaw.co.kr/uploadFiles/attach/${splitData[1]}`;
                            return (
                              <div
                                className="col-12 mb-2 px-0"
                                key={`prev_${v}`}
                              >
                                <a
                                  className="btn btn-sm btn-info custom-bg-info mr-3"
                                  href={`//cardlaw.co.kr/backside/payment/JS_payment_download_file_save.php?original=${splitData[1]}&renamed=${fileName}`}
                                  target="_blank"
                                >
                                  <i className="fa fa-download mr-2" /> 다운로드
                                </a>
                                {fileName.endsWith(".pdf") && (
                                  <a
                                    className="font-weight-bold text-decoration-underline"
                                    href={fileLink}
                                    download={fileName}
                                    target="_blank"
                                  >
                                    {fileName}
                                  </a>
                                )}
                                {!fileName.endsWith(".pdf") && (
                                  <a
                                    className="font-weight-bold text-decoration-underline"
                                    href={`//cardlaw.co.kr/backside/payment/JS_payment_download_file_save.php?original=${splitData[1]}&renamed=${fileName}`}
                                    target="_blank"
                                  >
                                    {fileName}
                                  </a>
                                )}
                              </div>
                            );
                          })}
                      </div>
                      {/*결제요청 담당자 및 연락처*/}
                      <div className="row text-secondary">
                        <div className="col-6 px-0 font-weight-bold">
                          결제요청 담당자
                        </div>
                        <div className="col-6 px-0">
                          {userInfo && userInfo["uName"]}
                        </div>
                        <div className="col-6 px-0 font-weight-bold">
                          결제요청 담당자 연락처
                        </div>
                        <div className="col-6 px-0">
                          {userInfo && util.telFormatConvert(userInfo["uTel"])}
                        </div>
                      </div>
                      {/*결제기업상호 및 이메일*/}
                      <div className="row my-3">
                        <div className="col-12 p-0 p-0 pb-2">결제 고객명</div>
                        <div className="col-12 p-0 pb-2">
                          <input
                            type="text"
                            placeholder="결제 고객명"
                            className="form-control"
                            defaultValue={paymentData.pmCompanyName}
                          />
                        </div>
                        <div className="col-12 p-0 pb-2">결제 고객 이메일</div>
                        <div className="col-12 p-0 pb-1">
                          <input
                            type="text"
                            placeholder="결제 고객 이메일"
                            className="form-control"
                          />
                        </div>

                        {paymentData.pmMemo && (
                          <div>
                            <div className="row row-cols-2 bg-info text-white py-3 my-3">
                              <div className="col-12">
                                전달 메모 : {paymentData.pmMemo}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      {/*이용약관*/}
                      <div className="row py-3 align-items-center border mb-3">
                        <div className="col-12">
                          <input id="personalInformation" type="checkbox" />
                          <label className="m-0" htmlFor="personalInformation">
                            개인정보 수집 및 이용동의
                            <span className="text-primary">(필수)</span>
                          </label>
                          <span
                            className="btn p-0 ml-2 font-weight-bold"
                            style={{
                              fontSize: "0.8rem",
                              textDecoration: "underline",
                            }}
                            data-toggle="collapse"
                            href="#collapseExample"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            자세히 보기
                          </span>
                          <div className=" collapse" id="collapseExample">
                            <div
                              className="container py-2 bg-light"
                              style={{
                                height: "200px",
                                overflow: "scroll",
                              }}
                            >
                              <p className="font-weight-bold">
                                수집하는 개인정보의 항목
                              </p>
                              <p>
                                회사는 카드로지스에서 제공하는
                                전자결제요청서비스(이하 “ 서비스”라 칭함)를
                                이용하여 대금의 결제요청, 취소, 환불 및
                                이와관련한 문의사항 등, 서비스의 제공을 위하여
                                아래와 같은 개인정보를 수집하고 있습니다.
                              </p>
                              <div className="container">
                                <div>
                                  <p>1. 개인정보 수집항목</p>
                                  <div className="container">
                                    <div>
                                      <p>1-1. 필수항목</p>
                                      <p>
                                        · 결제자의 이름, 이메일, 전화번호,
                                        담당자명, 담당자 휴대폰번호, 담당자
                                        이메일주소
                                      </p>
                                      <p>
                                        · 상기 명시된 개인정보 항목 이외의
                                        '서비스' 이용과정이나 '서비스'
                                        처리과정에서 다음과 같은 추가 정보들이
                                        자동 혹은 수동으로 생성되어 수집 될 수
                                        있습니다. 접속 IP/MAC Address, 쿠키,
                                        e-mail, 서비스 접속 일시, 서비스
                                        이용기록, 불량 혹은 비정상 이용기록,
                                        결제기록{" "}
                                      </p>
                                      <p>
                                        · 기타 회사는 서비스 이용과 관련한
                                        대금결제, 환불 등에 필요한 다음과 같은
                                        정보 등을 추가로 수집할 수 있습니다.
                                        계좌번호, 예금주명, 서비스계약일 등
                                      </p>
                                    </div>
                                    <div>
                                      <p>1-2. 선택항목</p>
                                      <p>
                                        · 필수항목 이외에 계약서류에 기재된 정보
                                        또는 고객이 제공한 정보
                                      </p>
                                      <p>· 주소, 팩스번호 등</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p>2. 수집방법</p>
                                  <div className="container">
                                    <div>
                                      <p>
                                        2-1. 결제요청서에 고객이 기입하여
                                        제시하는 개인정보 및 해당건과 관련하여
                                        이메일등 별도의 수단을 이용하여 제공하는
                                        개인정보를 수집
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p className="font-weight-bold">
                                개인정보의 수집 이용목적
                              </p>
                              <p>
                                회사는 수집한 개인정보를 다음의 목적을 위해
                                활용합니다.
                              </p>
                              <div className="container">
                                <div>
                                  <p>
                                    1. 서비스 제공 계약의 성립, 유지, 종료를
                                    위한 본인 식별 및 실명확인, 각종 회원관리,
                                    계약서 발송 등
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    2. 서비스 제공 과정 중 본인 식별, 인증,
                                    실명확인 및 각종 안내/고지
                                  </p>
                                </div>
                                <div>
                                  <p>3. 부정 이용방지 및 비인가 사용방지</p>
                                </div>
                                <div>
                                  <p>
                                    4. 서비스 제공 및 관련 업무처리에 필요한
                                    동의 또는 철회 등 의사확인
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    5. 이용 빈도 파악 및 인구통계학적 특성에
                                    따른 서비스 제공 및 CRM
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    6. 서비스 제공을 위한 각 결제수단 별 상점
                                    사업자 정보 등록
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    7. 기타 회사가 제공하는 이벤트 등 광고성
                                    정보 전달, 통계학적 특성에 따른 서비스 제공
                                    및 광고 게재, 실제 마케팅 활동
                                  </p>
                                </div>
                              </div>
                              <p className="font-weight-bold">
                                개인정보의 보유 및 이용기간
                              </p>
                              <p>
                                이용자의 개인정보는 원칙적으로 개인정보의 수집
                                및 이용목적이 달성되면 지체 없이 파기 합니다.
                                단, 다음의 각 목에 해당하는 정보에 대해서는
                                아래의 이유로 명시한 기간 동안 보존 합니다.
                              </p>
                              <div className="container">
                                <div>
                                  <p>1. 회사 내부 방침의 의한 정보보유</p>
                                  <div className="container">
                                    <div>
                                      <p>
                                        1-1. 보존항목: 서비스 상담 수집
                                        항목(회사명, 고객명, 전화번호, E-mail,
                                        상담내용 등)
                                      </p>
                                    </div>
                                    <div>
                                      <p>
                                        1-2. 보존이유: 분쟁이 발생 할 경우
                                        소명자료 활용
                                      </p>
                                    </div>
                                    <div>
                                      <p>1-3. 보존기간: 상담 완료 후 6개월</p>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <p>2. 관련법령에 의한 정보보유</p>
                                  <div className="container">
                                    <div>
                                      <p>
                                        2-1. 계약 또는 청약철회 등에 관한 기록
                                      </p>
                                      <p>· 보존기간: 5년</p>
                                      <p>
                                        · 보존근거: 전자상거래 등에서의
                                        소비자보호에 관한 법률
                                      </p>
                                    </div>
                                    <div>
                                      <p>
                                        2-2. 대금결제 및 재화 등의 공급에 관한
                                        기록
                                      </p>
                                      <p>· 보존기간: 5년</p>
                                      <p>
                                        · 보존근거: 전자상거래 등에서의
                                        소비자보호에 관한 법률
                                      </p>
                                    </div>
                                    <div>
                                      <p>
                                        2-3. 소비자의 불만 또는 분쟁처리에 관한
                                        기록
                                      </p>
                                      <p>· 보존기간: 3년</p>
                                      <p>
                                        · 보존근거: 전자상거래 등에서의
                                        소비자보호에 관한 법률
                                      </p>
                                    </div>
                                    <div>
                                      <p>2-4. 본인확인에 관한 기록</p>
                                      <p>· 보존기간: 6개월</p>
                                      <p>
                                        · 보존근거: 정보통신 이용촉진 및
                                        정보보호 등에 관한 법률
                                      </p>
                                    </div>
                                    <div>
                                      <p>2-5. 방문에 관한 기록</p>
                                      <p>· 보존기간: 3개월</p>
                                      <p>
                                        · 보존근거: 보존근거: 통신비밀보호법
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*다음 버튼*/}
                      <div className="row">
                        <div className="col-12 p-0">
                          <div className="btn btn-outline-info custom-btn-info w-100">
                            {" "}
                            다음
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentRequestRegister;
